// This file can be replaced during build by using the `fileReplacements` array1.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl: path
  // baseUrl: "http://34.66.70.237:8086/",
  // baseUrl: "http://34.66.70.237:8001/",
  //baseUrl: "http://34.72.47.156:8000/",
  // baseUrl: "https://apiuat-deductionslink.inmar.com/"
  // baseUrl: "https://fintech-dmp-api-uat-bryyvagkmq-uc.a.run.app/"
  // baseUrl: "https://apidev-deductionslink.inmar.com/"
  // baseUrl: "https://fintech-dmp-qa-api-bryyvagkmq-uc.a.run.app/"
  // baseUrl: path.api.url
  // baseUrl: "https://apidev-deductionslink.inmar.com/"
  // baseUrl: "https://apitest-vvkvyilurq-uc.a.run.app/"
  // baseUrl : "https://apiuat-deductionslink.inmar.com/"
  // 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
  // import 'zone.js/plugIns/zone-error';  // Included with Angular CLI.
