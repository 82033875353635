<div class="mb-1">
    <ngx-spinner bdColor="rgba(255, 255, 255, 0.7)" size="medium" color="#1579ab"
        type="ball-clip-rotate-multiple"></ngx-spinner>
    <div class="inmar-layout-container">
        <app-c-header></app-c-header>
        <div class="mx-4 mt-65em">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- this is the sidenav we are concerned with -->
    <mat-sidenav #panel class="panel app-matsidenav" position="end">
        <!-- this is where i want to load different components dynamically -->
        <ng-container #content></ng-container>
    </mat-sidenav>
    <mat-sidenav-container class="sidenav-container">
    </mat-sidenav-container>