import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnInit }
  from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { LoaderService } from "../../services/loader/loader.service";
import { CommonService } from "../../services/common/common.service";
import { SidenavService } from "../../services/sidenav.service";
import { WebsocketService } from "../../services/websocket.service";
import { UtilService } from "../../services/util/util.service";
import * as moment from "moment";
import { filter, startWith } from "rxjs/operators";

@Component({
  selector: "app-c-header",
  templateUrl: "./c-header.component.html",
  styleUrls: ["./c-header.component.scss"],
  providers: [WebsocketService]
})
export class CHeaderComponent implements OnInit, AfterViewChecked {
  showPromotionsTab: boolean = false;

  SelectedTab: any = "";
  SelectedCashTab: any = "";
  SelectedReportTab: any = "";
  SelectedConnectorTab: any = "";
  userInfo: any = [];
  userList: any = [];
  keys: any = []
  notificationCount = null;
  locationName: any = "";
  listOfTabs: any = [{
    tab: "All Documents",
    routePath: "all-documents"
  },
  {
    tab: "AR Documents",
    routePath: "ar-documents"
  },
  {
    tab: "New Remittance",
    routePath: "new-remitance"
  },
  {
    tab: "Validations",
    routePath: "validation"
  },
  {
    tab: "Awaiting Approval",
    routePath: "approvals"
  },
  {
    tab: "Approved Deductions",
    routePath: "approved-deduction"
  },
  {
    tab: "Track and Manage Disputes",
    routePath: "track-dispute"
  }];

  cashTabs: any = [
    {
      tab: "All Documents",
      routePath: "all-documents"
    },
    {
      tab: "AR Documents",
      routePath: "ar-documents"
    },
    {
      tab: "New Remittance",
      routePath: "new-remitance"
    },
    {
      tab: "New Payments",
      routePath: "new-payments"
    }];

  reportMenu: any = [
    {
      tab: "Remittance Summary",
      routePath: "new-remitance"
    },
    {
      tab: "Invoice Summary",
      routePath: "invoice-summary"
    },
    {
      tab: "Open Debit Memos",
      routePath: "debit-memos"
    },
    {
      tab: "Deduction by Customers",
      routePath: "deduction-by-customers"
    },
    // {
    //   tab: "Reporting Data",
    //   routePath: "master-report",
    // },
    // {
    //   tab: "Exported Reports",
    //   routePath: "exported-reports",
    // }
  ];

  connectorMenu: any = [
    {
      tab: "Connectors",
      routePath: "retailer-connector"
    }
  ];
  showManageMenu: boolean = false;
  showReportMenu: boolean = false;
  showConnectorMenu: boolean = false;
  showDashboardMenu: boolean = false;
  showCashMenu: boolean = false;
  showHead: boolean;
  notification: any;
  invoiceStatus: any;
  helpModal: boolean = false;
  ProfileIcon: any;
  background_color: string = "";

  constructor(
    private router: Router,
    private commonserv: CommonService,
    public loaderService: LoaderService,
    public sidenavService: SidenavService,
    private changeDetectorRef: ChangeDetectorRef,
    private WebsocketService: WebsocketService,
    private cdr: ChangeDetectorRef,
    public utilService: UtilService
  ) {
    this.getUserInfo("new");
    this.loaderService.userList.subscribe((data: any) => {
      this.userList = data;
    });
    WebsocketService.messages.subscribe((msg: any) => {
      if (msg) {
        let msgparse = JSON.parse(msg);
        this.notificationCount = msgparse.result
      }
    });
    this.loaderService.userInfo.subscribe((data: any) => {
      if (data.user_type == 4) {
        this.listOfTabs = [
          {
            tab: "Awaiting Approval",
            routePath: "approvals",
          }
        ]
      }
    });
  }

  ngOnInit() {
    this.loaderService.userInfo.subscribe((data: any) => {
      if (data && Object.keys(data).length !== 0) {
        this.showPromotionsTab = data.trade_promotions;
        if (this.showPromotionsTab && !this.listOfTabs.some(tab => tab.tab === 'Promotions')) {
          this.listOfTabs.splice(2, 0, {
            tab: "Promotions",
            routePath: "promotions"
          });
        }
        this.router.events.pipe(
          filter((e) => e instanceof NavigationStart),
          startWith(this.router)).subscribe(event => {
            let url = window.location.pathname;
            if (event instanceof NavigationStart) {
              url = event.url;
            };
            if (!(url.includes('login'))) {
              this.handleNavigation(url)
            }
          })
      }
    })
  }

  handleNavigation(url) {
    this.showDashboardMenu = false;
    this.locationName = url;
    if (url== "/login") {
      this.showHead = false;
    } else {
      this.showHead = true;
      if (url == "/dashboard") {
        this.showManageMenu = false;
        this.showDashboardMenu = true;
      }
      else {
        if (this.locationName == "/cash-link/all-documents") {
          this.SelectedCashTab = 0;
          this.showManageMenu = false;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = true;
        } else if (this.locationName == "/cash-link/ar-documents") {
          this.SelectedCashTab = 1;
          this.showManageMenu = false;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = true;
        } else if (this.locationName == "/cash-link/new-remitance") {
          this.SelectedCashTab = 2;
          this.showManageMenu = false;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = true;
        } else if (this.locationName == "/cash-link/new-payments") {
          this.SelectedCashTab = 3;
          this.showManageMenu = false;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = true;
        }

        if (this.locationName == "/manage-deductions/all-documents") {
          this.SelectedTab = 0;
          this.showManageMenu = true;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = false;
        } else if (this.locationName == "/manage-deductions/ar-documents") {
          this.SelectedTab = 1;
          this.showManageMenu = true;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = false;
        }
        else if (this.locationName == "/manage-deductions/promotions" && this.showPromotionsTab) {
          this.SelectedTab = 2;
          this.showManageMenu = true;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = false;
        }
        else if (this.locationName == "/manage-deductions/new-remitance") {
          this.SelectedTab = this.showPromotionsTab ? 3 : 2;
          this.showManageMenu = true;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = false;
        } else if (this.locationName == "/manage-deductions/validation") {
          this.SelectedTab = this.showPromotionsTab ? 4 : 3;
          this.showManageMenu = true;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = false;
        } else if (this.locationName == "/manage-deductions/approvals") {
          this.SelectedTab = this.showPromotionsTab ? 5 : 4;
          this.showManageMenu = true;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = false;
        } else if (this.locationName == "/manage-deductions/approved-deduction") {
          this.SelectedTab = this.showPromotionsTab ? 6 : 5;
          this.showManageMenu = true;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = false;
        } else if (this.locationName == "/manage-deductions/track-dispute") {
          this.SelectedTab = this.showPromotionsTab ? 7 : 6;
          this.showManageMenu = true;
          this.showReportMenu = false;
          this.showConnectorMenu = false;
          this.showCashMenu = false;
        }
        if (this.locationName == "/report/new-remitance") {
          this.SelectedReportTab = 0;
          this.showReportMenu = true;
          this.showManageMenu = false;
          this.showConnectorMenu = false;
        } else if (this.locationName == "/report/invoice-summary") {
          this.SelectedReportTab = 1;
          this.showReportMenu = true;
          this.showManageMenu = false;
          this.showConnectorMenu = false;
        } else if (this.locationName == "/report/debit-memos") {
          this.SelectedReportTab = 2;
          this.showReportMenu = true;
          this.showManageMenu = false;
          this.showConnectorMenu = false;
        } else if (this.locationName == "/report/deduction-by-customers") {
          this.SelectedReportTab = 3;
          this.showReportMenu = true;
          this.showManageMenu = false;
          this.showConnectorMenu = false;
        } else if (this.locationName == "/report/master-report") {
          this.SelectedReportTab = 4;
          this.showReportMenu = true;
          this.showManageMenu = false;
          this.showConnectorMenu = false;
        } else if (this.locationName == "/report/exported-reports") {
          this.SelectedReportTab = 5;
          this.showReportMenu = true;
          this.showManageMenu = false;
          this.showConnectorMenu = false;
        }
        else if (this.locationName == "/connector/retailer-connectors") {
          this.SelectedConnectorTab = 0;
          this.showConnectorMenu = true;
          this.showReportMenu = false;
          this.showManageMenu = false;
        }
      }
    }
  }
  ngAfterViewChecked() {
    this.locationName = window.location.pathname;
    if (this.userInfo && this.userInfo.first_name) {
      this.ProfileIcon = this.userInfo.first_name.charAt(0);
      this.cdr.detectChanges()
    }
    if (this.background_color == "") {
      this.background_color = "hsl(" + Math.random() * 360 + ", 100%, 90%)";
    }
  }

  Helpmodal(event) {
    this.helpModal = true;
    event.stopPropagation();
  }
  @HostListener('document:click', ['$event'])

  clickout(event) {
    this.helpModal = false;
  }


  selectMenu(event: any) {
    const tab = this.listOfTabs[event.index];

    if (this.userInfo.user_type == 4) {
      if (event.index == 0) {
        this.router.navigate(["/manage-deductions/approvals"]);
      }
    } else {
      switch (tab?.routePath) {
        case 'all-documents':
          this.router.navigate(["/manage-deductions/all-documents"]);
          break;
        case 'ar-documents':
          this.router.navigate(["/manage-deductions/ar-documents"]);
          break;
        case 'promotions':
          this.router.navigate(["/manage-deductions/promotions"]);
          break;
        case 'new-remitance':
          this.router.navigate(["/manage-deductions/new-remitance"]);
          break;
        case 'validation':
          this.router.navigate(["/manage-deductions/validation"]);
          break;
        case 'approvals':
          this.router.navigate(["/manage-deductions/approvals"]);
          break;
        case 'approved-deduction':
          this.router.navigate(["/manage-deductions/approved-deduction"]);
          break;
        case 'track-dispute':
          this.router.navigate(["/manage-deductions/track-dispute"]);
          break;
        default:
          break;
      }
    }
  }


  selectCashMenu(event) {
    if (event.index == 0) {
      this.router.navigate(["/cash-link/all-documents"]);
    } else if (event.index == 1) {
      this.router.navigate(["/cash-link/ar-documents"]);
    } else if (event.index == 2) {
      this.router.navigate(["/cash-link/new-remitance"]);
    } else if (event.index == 3) {
      this.router.navigate(["/cash-link/new-payments"]);
    }
  }
  triggerCashLink() {
    this.showCashMenu = true;
    this.showManageMenu = false;
    this.showReportMenu = false;
    this.showConnectorMenu = false;
    this.SelectedCashTab = 0;
    this.router.navigate(["/cash-link/all-documents"]);
  }

  selectReportMenu(event) {
    if (event.index == 0) {
      this.router.navigate(["/report/new-remitance"]);
    } else if (event.index == 1) {
      this.router.navigate(["/report/invoice-summary"]);
    } else if (event.index == 2) {
      this.router.navigate(["/report/debit-memos"]);
    } else if (event.index == 3) {
      this.router.navigate(["/report/deduction-by-customers"]);
    } else if (event.index == 4) {
      this.router.navigate(["/report/master-report"]);
    } else if (event.index == 5) {
      this.router.navigate(["/report/exported-reports"]);
    }
  }

  selectConnectorMenu(event) {
    if (event.index == 0) {
      this.router.navigate(["/connector/retailer-connectors"]);
    }
  }

  triggerManageDeductions() {
    this.showManageMenu = true;
    this.showReportMenu = false;
    this.showConnectorMenu = false;
    this.showCashMenu = false;
    if (this.userInfo.user_type == 4) {
      this.SelectedTab = 0;
      this.router.navigate(["/manage-deductions/approvals"]);
    } else {
      this.SelectedTab = this.showPromotionsTab ? 3 : 2;;
      this.router.navigate(["/manage-deductions/new-remitance"]);
    }
  }
  triggerReport() {
    this.showReportMenu = true;
    this.showManageMenu = false;
    this.showConnectorMenu = false;
    this.SelectedReportTab = 0;
    this.showCashMenu = false;
    this.router.navigate(["/report/new-remitance"]);
  }
  triggerConnector() {
    this.showConnectorMenu = true;
    this.showReportMenu = false;
    this.showManageMenu = false;
    this.showCashMenu = false;
    this.SelectedConnectorTab = 0;
    this.router.navigate(["/connector/retailer-connectors"]);
  }

  triggerDashboard() {
    this.showManageMenu = false;
    this.showReportMenu = false;
    this.showConnectorMenu = false;
    this.showCashMenu = false;
    this.router.navigate(["/dashboard"]);
  }
  bindUserImage(image) {
    if (image && image != null) {
      return image;
    } else {
      return "assets/inmar-icon/ICONS/user-circle.svg";
    }
  }
  getUserInfo(type) {
    // if (type == "update") {
    //   var data = {}
    //   this.commonserv.getUserInfo(data).subscribe((res: any) => {
    //     this.userInfo = res;
    //   });
    // } else {
    this.loaderService.userInfo.subscribe((data: any) => {
      this.userInfo = data;
    });
    // }
  }
  Logout() {
    sessionStorage.removeItem("api_token");
    window.location.href = JSON.parse(localStorage.getItem('baseUrl')).sso.url;
  }

  getNotification() {
    this.commonserv.getInvoiceMessages().subscribe((res: any) => {
      this.objectKeys(res['datas'])
      if (res['result'] == 0) {
        this.notification = []
        this.keys = []
      } else {
        this.notification = res['datas']

      }
    })
  }
  objectKeys(obj) {
    var keys = [];
    if (!obj) return keys;
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.push(key);
      }
    }
    this.keys = keys
  }
  clearAllNotification(notificationid, clearFlag) {
    this.commonserv.clearNotification(notificationid).subscribe((res: any) => {
      if (clearFlag == "clearAll") {
        this.getNotification();
      }
      if (res.result == 1 && notificationid) {
        for (let item of this.keys) {
          for (var i = this.notification[item].length - 1, msg = this.notification[item]; i >= 0; --i) {
            if (msg[i].notification_id == notificationid) {
              this.notification[item].splice(i, 1)
            }
          }
          if (this.notification[item].length === 0) {
            this.getNotification();
          }
        }
      }
      if (this.keys.length == 0) {
        this.notification = []
        this.keys = []
      }
      this.changeDetectorRef.detectChanges();
    })
  }

  openActivitySideNavBar(notify: any) {
    if (!Number(notify.details.id)) return;
    this.commonserv.getInvoiceProcessingStatus(notify.details.id).subscribe((res: any) => {
      let routerType = res.data[0].invoices_values[0].column_value;
      let invoiceData = notify.details.id
      if (routerType == 'Open') this.redirctToRouter('/manage-deductions/validation', invoiceData);
      else if (routerType == 'Awaiting Approval' || routerType == "Awaiting Approval-Level 1" ||
        routerType == "Awaiting Approval-Level 2" || routerType == "Awaiting Approval-Level 3") {
        this.redirctToRouter('/manage-deductions/approvals', invoiceData);
      } else if (routerType == 'Dispute') this.redirctToRouter('/manage-deductions/track-dispute', invoiceData);
      else if (routerType == 'Approved' || routerType == 'Threshold Credit') this.redirctToRouter('/manage-deductions/approved-deduction', invoiceData);
      else {
        const condition = this.utilService.getConditions('contains');
        let searchUrl = `&InvoiceNumber=${condition}:${notify.details.number}`;
        const encodedUrl = encodeURIComponent(searchUrl.trim());
        this.commonserv.getSingleInvoiceData(encodedUrl).subscribe((res: any) => {
          if (res && res.items && res.items.length) {
            let row = null
            res.items.forEach((element) => {
              if (element.InvoiceID == notify.details.id) row = element
            })
            this.redirctToRouter('/report/invoice-summary', row);
          }
        })
      }
    })
    this.sidenavService.close()
  }

  redirctToRouter(url: string, data) {
    let routerData = { state: { openActivityInvoice: data } }
    if (this.router.url == url) this.loaderService.openActivityInvoice.next(data);
    else this.router.navigate([url], routerData)
  }

  shortMsg(msg) {
    return msg.substring(0, 80)
  }

  getNameByID(id) {
    var item = this.userList.find(item => item.Id === id);
    return item.FirstName + ' ' + item.LastName
  }
  getEST(date) {
    var cutoffString = date; // in utc
    var utcCutoff = moment.utc(cutoffString, "YYYYMMDD HH:mm:ss");
    var displayCutoff = utcCutoff.clone().tz("America/New_York");
    return displayCutoff.toString();
  }
}
