import { Pipe, PipeTransform, TemplateRef } from '@angular/core';
import { UtilService } from '../../services/util/util.service';

@Pipe({
    name: 'templateRef'
})
export class TemplateRefPipe implements PipeTransform {

    constructor(private registry: UtilService) { }

    transform(name: string): TemplateRef<any> | undefined {
        return this.registry.templates[name];
    }
}