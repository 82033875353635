import { Component } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CommonService } from "../../services/common/common.service";
import { LoaderService } from "../../services/loader/loader.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  loginForm: UntypedFormGroup;
  isLogin: any = false;

  showPassword: boolean = false;
  submitted = false;
  disputeOptions: any = [];
  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public popUp: LoaderService,
    private loader: LoaderService,
    private commonserv: CommonService
  ) {
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    });
  }
  // for accessing to form fields
  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    else if (this.loginForm.value.email == "migillis" &&
      this.loginForm.value.password == "3eC_Qp!XNW") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "I7IjF0JGbk79ifvkXm5Y71");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    } else if (this.loginForm.value.email == "inmarops" &&
      this.loginForm.value.password == "4fD_Rq!YOX1") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "I7IjF0JGbk79ifvkXm5Y72");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    }
    else if (this.loginForm.value.email == "prmazumdar" &&
      this.loginForm.value.password == "4fD_Rq!YOX2") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "I7IjF0JGbk79ifvkXm5Y73");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    }
    else if (this.loginForm.value.email == "ibuser" &&
      this.loginForm.value.password == "4fD_Rq!YOX3") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "I7IjF0JGbk79ifvkXm5Y74");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    } else if (this.loginForm.value.email == "pbtest1" &&
      this.loginForm.value.password == "4fD_Rq!YOX4") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "T3I7IjF0JGbk79ifvkXm5Y71");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    }
    else if (this.loginForm.value.email == "pbtest2" &&
      this.loginForm.value.password == "4fD_Rq!YOX5") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "T3I7IjF0JGbk79ifvkXm5Y72");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    }
    else if (this.loginForm.value.email == "dex.brooks" &&
      this.loginForm.value.password == "4fD_R4q!YOX6") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "T2I7IjF0JGbk79ifvkXm5Y71");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    } else if (this.loginForm.value.email == "inmarops.saffron" &&
      this.loginForm.value.password == "4fD_R2q!YOX7") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "T2I7IjF0JGbk79ifvkXm5Y7211");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    } else if (this.loginForm.value.email == "hasan.ali" &&
      this.loginForm.value.password == "4fD_R2q!YOX8") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "T3I7IjF0JGbk79ifvkXm5Y71");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    } else if (this.loginForm.value.email == "inmarops.voss" &&
      this.loginForm.value.password == "4fD_R2q!YOX9") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "T3I7IjF0JGbk79ifvkXm5Y72");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    } else if (this.loginForm.value.email == "jordan.bodine" &&
      this.loginForm.value.password == "4fD_R2q!YOX10") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "T4I7IjF0JGbk79ifvkXm5Y71");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    } else if (this.loginForm.value.email == "inmarops.himalayausa" &&
      this.loginForm.value.password == "4fD_R2q!YOX11") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "T4I7IjF0JGbk79ifvkXm5Y72");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    } else if (this.loginForm.value.email == "ibdemo" &&
      this.loginForm.value.password == "4fD_R2q!YOX12") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "D1I7IjF0JGbk79ifvkXm5Y71");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    } else if (this.loginForm.value.email == "inmardemo" &&
      this.loginForm.value.password == "4fD_R2q!YOX13") {
      this.router.navigate(["/manage-deductions/new-remitance"]);
      sessionStorage.setItem("sessionToken", "D1I7IjF0JGbk79ifvkXm5Y72");
      this.getApproverList();
      this.getDeductionOptionList();
      this.getProcessingStatusList();
      this.getProcessorList();
      this.getDisbuteOptionList();
    } else {
      this.popUp.customeHandler("Please check the email and password", "Invalid credentials", "toast-top-right");
    }
  }
  triggerPassword() {
    this.showPassword = !this.showPassword;
  }
  getApproverList() {
    this.commonserv.userList(0).subscribe((res: any) => {
      this.loader.approverCustomList.next(res.users_data);
    });
  }
  getDeductionOptionList() {
    var data = {
      session_id: sessionStorage.getItem("sessionToken"),
    };
    this.commonserv.getDeductionTypeDetails(data).subscribe((res: any) => {
      this.loader.deductionCustomList.next(res.deductions_type_list);
    });
  }
  getProcessingStatusList() {
    let proccesorStatusCustomList = [];
    this.loader.proccesorStatusCustomList.subscribe((res: any) => {
      if (res) {
        proccesorStatusCustomList = res;
      } else {
        this.commonserv.getProcessingStatusDetails({}).subscribe((res: any) => {
          for (let item of res['processing_status_list']) {
            proccesorStatusCustomList.push({
              id: item,
              name: item,
              disabled: item !== "Split" && item !== "Not Applicable" ? false : true
            });
          }
        });
      }
    })
  }
  getProcessorList() {
    var data = {
      session_id: sessionStorage.getItem("sessionToken"),
    };
    this.commonserv.userList(1).subscribe((res: any) => {
      var processorOptions = [];
      if (res.result != 0) {
        processorOptions = res.users_data;
      }
      this.loader.processorCustomList.next(res.users_data);
    });
  }
  getDisbuteOptionList() {
    var data = {
      session_id: sessionStorage.getItem("sessionToken"),
    };
    this.commonserv.getDisputeStatusDetails(data).subscribe((res: any) => {
      if (
        res &&
        res.dispute_status_list &&
        res.dispute_status_list.length > 0
      ) {
        res.dispute_status_list.forEach((list, i) => {
          this.disputeOptions.push({
            id: i + 1,
            name: list,
          });
        });
      }
    });
  }
}
