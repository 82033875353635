import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})

export class LoaderService {
  loaderSubject = new Subject<boolean>();
  loaderState = this.loaderSubject.asObservable();
  isLoad = true;
  productGroupList = new BehaviorSubject(Array);
  customerSegmentList = new BehaviorSubject(Array);
  userInfo = new BehaviorSubject(Array);
  documentTypeListOptions = new BehaviorSubject(Array);
  resizeColumnLoader = new BehaviorSubject({});
  isReloadClick = new BehaviorSubject({});
  filterUpdate =  new BehaviorSubject({});
  updateStickyStylesLoader = new BehaviorSubject({});
  checkboxSelection = new BehaviorSubject({});
  dropdownselectedInfo = new BehaviorSubject({});
  isInvoiceDataLoaded = new BehaviorSubject({});
  isUpdateColumns = new BehaviorSubject([]);
  isUpdateTableData = new BehaviorSubject(Array);
  isUpdatedModalTableData = new BehaviorSubject(Array);
  isUpdatedModalDeductionTableData = new BehaviorSubject(Array);
  textboxUpdateValueInfo = new BehaviorSubject({});
  // custom component changes
  approverCustomList = new BehaviorSubject(Array);
  levelApproverCustomList = new BehaviorSubject(Array);
  approverCustomListForMemoChat = new BehaviorSubject([]);
  deductionCustomList = new BehaviorSubject(Array);
  creditLineCustomItemList = new BehaviorSubject(Array);
  brandCustomList = new BehaviorSubject(Array);
  groupCustomList = new BehaviorSubject(Array);
  categoryCustomList = new BehaviorSubject(Array);
  segmentCustomList = new BehaviorSubject(Array);
  proccesorStatusCustomList = new BehaviorSubject(Array);
  processorCustomList = new BehaviorSubject(Array);
  itemizationCustomStatusList = new BehaviorSubject(Array);
  disputeStatusList = new BehaviorSubject(Array);
  customerERPIdList = new BehaviorSubject(Array);
  userList = new BehaviorSubject(Array);
  customerSubGroupList = new BehaviorSubject(Array);
  openActivityInvoice = new BehaviorSubject(null);
  showDisputeSuccess = new BehaviorSubject({});
  updatedDisputeData = new BehaviorSubject({});
  customerOptions = new BehaviorSubject(Array);
  autoCompleteClose = new BehaviorSubject({});
  showExportReportSuccess = new BehaviorSubject({});
  showExportLoader = new BehaviorSubject(null);
  
  gapAmountDetails = new Subject<number>();
  constructor(public toastr: ToastrService) {
  }
  private promotionDataSource = new BehaviorSubject<any>(null);
  promotionData$ = this.promotionDataSource.asObservable();

  setPromotionDropdownData(data: any) {
    this.promotionDataSource.next(data);
  }

  public messageSource = new BehaviorSubject("default message");
  currentMessage = this.messageSource.asObservable();
  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  showError() {
    this.toastr.error("Something Went Wrong, Please try again later", "Error", {
      timeOut: 3000
    });
  }

  customeHandler(event, type, position) {
    this.toastr.error(event, type, {
      timeOut: 3000,
      positionClass: position
    });
  }

  sucessHandler(event, type, position) {
    this.toastr.success(event, type, {
      timeOut: 3000,
      positionClass: position
    });
  }
}
