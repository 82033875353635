import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { LoaderService } from "./loader/loader.service";

@Injectable({
  providedIn: "root",
})

export class HttpInterceptorService implements HttpInterceptor {
  apiCalssReq: any = 0;
  apiCalssResponse: any = 0;
  constructor(private loaderService: LoaderService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url.search('fetchUsersList') == -1 && request.url.search('splitReverse') == -1 && request.url.search('fetchCustomerSubGroup') == -1) {
      this.showLoader();
    } else {
      this.apiCalssReq++;
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.apiCalssResponse++;
            this.onEnd();
          }
        },
        (err: any) => {
          this.onEnd();
          if (err.status == 401) {
            sessionStorage.removeItem("api_token");
            window.location.href = JSON.parse(localStorage.getItem('baseUrl')).sso.url;
          }
          else if (err.status == 422) {
            this.apiCalssReq = 0;
            this.apiCalssResponse = 0;
            this.loaderService.loaderSubject.next(false);
            this.loaderService.isLoad = true;
          } else if (err.status == 413) {
            this.apiCalssReq = 0;
            this.apiCalssResponse = 0;
            this.loaderService.loaderSubject.next(false);
            this.loaderService.isLoad = true;
            this.loaderService.customeHandler(
              "Please select files lesser than 15MB",
              "",
              "toast-top-right"
            );
          } else if (this.inRange(err.status, 399, 599)) {
            this.apiCalssReq = 0;
            this.apiCalssResponse = 0;
            this.loaderService.loaderSubject.next(false);
            this.loaderService.isLoad = true;
            this.loaderService.customeHandler(
              err.error.message,
              "",
              "toast-top-right"
            );
          } else {
            this.loaderService.showError();
            this.apiCalssResponse++;
          }
        }));
  }

  inRange(x, min, max) {
    return ((x - min) * (x - max) <= 0);
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.apiCalssReq++;
    const isLoad = this.loaderService.isLoad;
    this.loaderService.loaderSubject.next(isLoad);
    this.loaderService.loaderSubject.next(isLoad);
  }

  private hideLoader(): void {
    if (this.apiCalssReq == this.apiCalssResponse) {
      this.apiCalssReq = 0;
      this.apiCalssResponse = 0;
      this.loaderService.loaderSubject.next(false);
      this.loaderService.isLoad = true;
    }
  }
}
