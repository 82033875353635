import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplaceunderscorePipe } from './replaceunderscore.pipe';
import { TemplateRefPipe } from './template-ref.pipe';

@NgModule({
  declarations: [ReplaceunderscorePipe, TemplateRefPipe],
  imports: [
    CommonModule
  ],
  exports: [ReplaceunderscorePipe, TemplateRefPipe]
})
export class PipesModule { }
