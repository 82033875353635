import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CHeaderComponent } from './shared-components/c-header/c-header.component';
import { AngularMaterialModule } from './shared-modules/angular-material/material-ui/material-ui.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './services/httpconfig.interceptor.service';
import { LoginComponent } from './shared-components/login/login.component';
import { AppInitService } from './services/app-init/app-init.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from './utils/pipes/pipes.module';
import { DirectiveModule } from './utils/directive/directive.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { CurrencyPipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    CHeaderComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FlexLayoutModule,
    NgSelectModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    MatSidenavModule,
    PipesModule,
    DirectiveModule,
    NgxSpinnerModule
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: (AppInitService: AppInitService) => () => AppInitService.loadEnvronments().toPromise(),
      deps: [AppInitService],
      multi: true
    },
    HttpClientModule,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    CurrencyPipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  exports: [
    MatDialogModule,
    MatProgressBarModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
